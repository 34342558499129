import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/pmaxh/Documents/node-projects/website/src/components/page-layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AsciinemaEmbed = makeShortcode("AsciinemaEmbed");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Chat is a simple tcp-based chat client and server that supports many commands:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`/help`}</inlineCode><br parentName="li"></br>
        {`Displays supported commands`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`/changename <user> <new name>`}</inlineCode>{` (admin-only)`}<br parentName="li"></br>
        {`Changes the name of a user`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`/list`}</inlineCode><br parentName="li"></br>
        {`Lists current users`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`/quit`}</inlineCode>{` (admin-only)`}<br parentName="li"></br>
        {`Stops the server`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`/banip <ip>`}</inlineCode>{` (admin-only)`}<br parentName="li"></br>
        {`Bans a given IP from connecting and disconnects any current connections with that IP.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`/whois <name>`}</inlineCode><br parentName="li"></br>
        {`Displays the IP address associated with the given name`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`/promote <name>`}</inlineCode><br parentName="li"></br>
        {`Promotes the given user to admin.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`/demote <name>`}</inlineCode><br parentName="li"></br>
        {`Demotes the given user to normal user.`}</li>
    </ul>
    <h2>{`Demo`}</h2>
    <AsciinemaEmbed id="320173" width="700px" height="300px" mdxType="AsciinemaEmbed" />
    <h2>{`Source`}</h2>
    <p>{`Source code for `}<inlineCode parentName="p">{`chat`}</inlineCode>{` is available `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/chat"
      }}>{`on Github`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      